import React from 'react'
import { Helmet } from 'react-helmet'

const Meta = ({title, description, keywords}) => {
    return (
        <Helmet>
            <title>{title}</title>
            <meta name='description' content={description} />
            <meta name='keywords' content={keywords} /> 
        </Helmet>
    )
}

Meta.defaultProps = {
    title: 'Welcome to Banaly Ecom',
    description: 'Best Ever Ecom Platform.',
    keywords: 'ecom, software, erp, sales, online sales'
}
export default Meta
