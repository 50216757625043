import axios from 'axios'
import { CART_CLEAR_ITEMS } from '../constants/cartConstants';
import {
    ORDER_CREATE_REQUEST,
    ORDER_CREATE_SUCCESS,
    ORDER_CREATE_FAIL,
    ORDER_DETAILS_REQUEST,
    ORDER_DETAILS_SUCCESS,
    ORDER_DETAILS_FAIL,
    ORDER_PAY_REQUEST,
    ORDER_PAY_SUCCESS,
    ORDER_PAY_FAIL,
    ORDER_RP_CREATE_REQUEST,
    ORDER_RP_CREATE_SUCCESS,
    ORDER_RP_CREATE_FAIL,
    MAKE_PAYMENT_SUCCESS,
    MAKE_PAYMENT_FAIL,
    MAKE_PAYMENT_REQUEST,
    ORDER_PAYMENT_RESET,
    MY_ORDER_LIST_REQUEST,
    MY_ORDER_LIST_SUCCESS,
    MY_ORDER_LIST_FAIL,
    ORDER_LIST_REQUEST,
    ORDER_LIST_SUCCESS,
    ORDER_LIST_FAIL,
    ORDER_DELIVER_REQUEST,
    ORDER_DELIVER_SUCCESS,
    ORDER_DELIVER_FAIL
} from '../constants/orderConstants';

export const createOrder = (order) => async (dispatch, getState) => {
    try {
        dispatch({
            type: ORDER_CREATE_REQUEST
        })
        const { userLogin: { userInfo } } = getState()
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }
        const { data } = await axios.post(`https://api.banaly.in/api/orders`, order, config)
        console.log("Data is " + JSON.stringify(data))
        dispatch({
            type: ORDER_CREATE_SUCCESS,
            payload: data
        })
        dispatch({
            type: CART_CLEAR_ITEMS
        })
        localStorage.setItem('cartItems', JSON.stringify(getState().cart.cartItems))
    } catch (error) {
        dispatch({
            type: ORDER_CREATE_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        })
    }
}

export const getOrderDetails = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: ORDER_DETAILS_REQUEST
        })
        const { userLogin: { userInfo } } = getState()
        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`
            }
        }
        const { data } = await axios.get(`https://api.banaly.in/api/orders/${id}`, config)
        dispatch({
            type: ORDER_DETAILS_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: ORDER_DETAILS_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        })
    }
}

export const getRazorPayOrderId = (orderId, paymentAmt) => async (dispatch, getState) => {
    console.log("inside getrazorpayorderid")
    try {
        dispatch({
            type: ORDER_RP_CREATE_REQUEST
        })
        const { userLogin: { userInfo } } = getState()
        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`
            }
        }
        const { data } = await axios.get(`https://api.banaly.in/api/orders/${orderId}/createrporder`, config)
        dispatch({
            type: ORDER_RP_CREATE_SUCCESS,
            payload: data
        })
        dispatch(
            makeOrderPayment(orderId, data.paymentorder.id, paymentAmt)
        )
    } catch (error) {
        dispatch({
            type: ORDER_RP_CREATE_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        })
    }
}

export const makeOrderPayment = (orderId, paymentorderid, amount) => async (dispatch, getState, history) => {
    try {
        dispatch({
            type: MAKE_PAYMENT_REQUEST
        })
        const { userLogin: { userInfo:{name, email} } } = getState()
        // const config = {
        //     headers: {
        //         'Content-Type': 'application/json',
        //         Authorization: `Bearer ${userInfo.token}`
        //     }
        // }
        const { data: razorPayKeyId } = await axios.get('https://api.banaly.in/api/config/razorpay')
        console.log("Razor Pay Key id :" + razorPayKeyId)
        var options = {
            "key": razorPayKeyId,
            "amount": amount, // 2000 paise = INR 20, amount in paisa
            "name": "",
            "description": "",
            'order_id': paymentorderid,
            "handler": function (response) {
                console.log("Response from RazorPay is :" + JSON.stringify(response));
                if (response && response.razorpay_signature) {
                    dispatch({
                        type: MAKE_PAYMENT_SUCCESS,
                        payload: response
                    })
                    dispatch(payOrder(orderId, response))
                }
            },
            "prefill": {
                "name": name,
                "email": email,
            },
            "notes": {
                "address": "Testing Payment"
            },
            "theme": {
                "color": "black"
            }
        };
        var rzp1 = new window.Razorpay(options);
        rzp1.open();
    } catch (error) {
        dispatch({
            type: MAKE_PAYMENT_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        })
    }
}


export const payOrder = (orderId, paymentResponse) => async (dispatch, getState, history) => {
    console.log("payment response " + JSON.stringify(paymentResponse))
    try {
        dispatch({
            type: ORDER_PAY_REQUEST
        })
        const { userLogin: { userInfo } } = getState()
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }
        const { data } = await axios.put(`https://api.banaly.in/api/orders/${orderId}/pay`, paymentResponse, config)
        dispatch({
            type: ORDER_PAY_SUCCESS,
            payload: data
        })
        dispatch(getOrderDetails(orderId))
                    dispatch({
                    type: ORDER_PAYMENT_RESET
                    })
        
    } catch (error) {
        dispatch({
            type: ORDER_PAY_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        })
    }
}

export const getMyOrderList = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: MY_ORDER_LIST_REQUEST
        })
        const { userLogin: { userInfo } } = getState()
        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`
            }
        }
        const { data } = await axios.get(`https://api.banaly.in/api/orders/myorders`, config)
        dispatch({
            type: MY_ORDER_LIST_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: MY_ORDER_LIST_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        })
    }
}

export const listOrders = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: ORDER_LIST_REQUEST
        })
        const { userLogin: { userInfo } } = getState()
        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`
            }
        }
        const { data } = await axios.get(`https://api.banaly.in/api/orders`, config)
        dispatch({
            type: ORDER_LIST_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: ORDER_LIST_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        })
    }
}

export const deliverOrder = (order) => async (dispatch, getState, history) => {
    try {
        dispatch({
            type: ORDER_DELIVER_REQUEST
        })
        const { userLogin: { userInfo } } = getState()
        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`
            }
        }
        const { data } = await axios.put(`https://api.banaly.in/api/orders/${order._id}/deliver`, order, config)
        dispatch({
            type: ORDER_DELIVER_SUCCESS,
            payload: data
        })     
    } catch (error) {
        dispatch({
            type: ORDER_DELIVER_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        })
    }
}